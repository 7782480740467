import React from 'react'
import Helmet from 'react-helmet'

import GetStartedList from '../components/GetStartedList/GetStartedList'

import { metaData } from '../meta'
import { SUBSCRIPTION, LICENSE } from '../constants/constants'

let scrolled25PercentYet, scrolled50PercentYet, scrolled75PercentYet, scrolled100PercentYet

class GetStartedIndex extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      subscription: SUBSCRIPTION.INDIVIDUAL,
      license: LICENSE.VR
    }
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll, true)
    scrolled25PercentYet = false
    scrolled50PercentYet = false
    scrolled75PercentYet = false
    scrolled100PercentYet = false

    const params = new URLSearchParams(this.props.location.search)
    this.setState({
      subscription: params.get('subscription') || SUBSCRIPTION.INDIVIDUAL,
      license: params.get('license') || LICENSE.VR
    })
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll, true)
  }

  handleScroll() {
    const winScroll = document.body.scrollTop || document.documentElement.scrollTop
    const height = document.documentElement.scrollHeight - document.documentElement.clientHeight

    const scrolledPercentage = winScroll / height // 0 to 1

    window.dataLayer = window.dataLayer || []
    if (scrolledPercentage >= 0.25 && !scrolled25PercentYet) {
      console.log('gtm-behavior-get-started-scroll-depth-25')
      window.dataLayer.push({
        event: 'gtm-behavior-get-started-scroll-depth-25'
      })
      scrolled25PercentYet = true
    } else if (scrolledPercentage >= 0.5 && !scrolled50PercentYet) {
      console.log('gtm-behavior-get-started-scroll-depth-50')
      window.dataLayer.push({
        event: 'gtm-behavior-get-started-scroll-depth-50'
      })
      scrolled50PercentYet = true
    } else if (scrolledPercentage >= 0.75 && !scrolled75PercentYet) {
      console.log('gtm-behavior-get-started-scroll-depth-75')
      window.dataLayer.push({
        event: 'gtm-behavior-get-started-scroll-depth-75'
      })
      scrolled75PercentYet = true
    } else if (scrolledPercentage >= 1 && !scrolled100PercentYet) {
      console.log('gtm-behavior-get-started-scroll-depth-100')
      window.dataLayer.push({
        event: 'gtm-behavior-get-started-scroll-depth-100'
      })
      scrolled100PercentYet = true
    }
  }

  render() {
    return (
      <div style={{ background: '#fff' }}>
        <Helmet title={metaData.getStarted.title}>
          <meta name="description" content={metaData.getStarted.description} />
          <meta name="keywords" content={metaData.getStarted.keywords} />
        </Helmet>
        <GetStartedList
          subscription={this.state.subscription}
          license={this.state.license}
          onChange={(data) => this.setState(data)}
        />
      </div>
    )
  }
}

export default GetStartedIndex
