export const ABOUT_US_OPTIONS = [
  {
    value: 'SelectOption',
    name: 'Select Option...'
  },
  {
    value: 'FacebookAd',
    name: 'Facebook Ad'
  },
  {
    value: 'FacebookPage',
    name: 'Facebook Page'
  },
  {
    value: 'InstagramAd',
    name: 'Instagram Ad'
  },
  {
    value: 'InstagramProfile',
    name: 'Instagram Profile'
  },
  {
    value: 'LinkedInAd',
    name: 'LinkedIn Ad'
  },
  {
    value: 'LinkedInPage',
    name: 'LinkedIn Page'
  },
  {
    value: 'YouTubeAd',
    name: 'YouTube Ad'
  },
  {
    value: 'YouTubeChannel',
    name: 'YouTube Channel'
  },
  {
    value: 'GoogleAd',
    name: 'Google Ad'
  },
  {
    value: 'Twitter',
    name: 'Twitter'
  },
  {
    value: 'WebArticle',
    name: 'Web Article'
  },
  {
    value: 'Tradeshow',
    name: 'Tradeshow'
  },
  {
    value: 'VRHardwareVendor',
    name: 'VR Hardware Vendor'
  },
  {
    value: 'Reseller',
    name: 'Reseller'
  },
  {
    value: 'Friend',
    name: 'Friend'
  },
  {
    value: 'Colleague',
    name: 'Colleague'
  },
  {
    value: 'Other',
    name: 'Other'
  }
]

export const SUBSCRIPTION = {
  INDIVIDUAL: 'individual',
  ORGANIZATION: 'organization'
}

export const LICENSE = {
  VR: 'vr',
  MONITOR: 'monitor'
}

export const FORM_SPREE_ENDPOINT = 'https://formspree.io/contact@ovationvr.com' // https://formspree.io/contact@ovationvr.com, https://formspree.io/f/meojqwve
export const RECAPTCHA_SITE_KEY = '6Ldz7w0dAAAAAEa44mUbuCO2JZ2lMaZzUAQRmqt7' // 6Ldz7w0dAAAAAEa44mUbuCO2JZ2lMaZzUAQRmqt7, 6Lcasg0dAAAAAAn9MaqvQAxbUTv7ditfRymxmD87
